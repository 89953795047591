.Login {
  min-height: 70vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 500px;
    margin-top: 16px;

    input {
      width: 100%;
      padding: 12px 12px;
      margin: 4px 0;
      font-size: 16px;
      border-radius: 12px;
      border: 1px solid #ddd;
      background: none;
    }

    button {
      margin-top: 10px;
      font-size: 18px;
    }
  }
}