.hero {
  min-height: 568px;
  // background-image: linear-gradient(to right, rgba(lighten($color-primary, 50), .8) 45%,rgba(lighten($color-primary, 40), .15)), url('../../../assets/images/hero-bg.jpg');
  // background-image: linear-gradient(to right, rgba(lighten($color-primary, 50), .3) 45%,rgba(lighten($color-primary, 40), .05)), url('../../../assets/images/bg-4.jpg');
  background-image: linear-gradient(to right, rgba($color-primary,.4), rgba(0,0,0,.1)), url('../../../assets/images/bg-4.jpg');
  background-size: cover;
  background-position: 0% 15%;
  background-repeat: no-repeat;
  background-color: $color-test;

  display: flex;
  align-items: center;
  justify-content: center;

  &-text {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    // color: #505050;
    // color: hsl(0, 0%, 30%);
    color: hsl(0, 0%, 98%);
    text-shadow: 1px 2px 4px rgba(0,0,0,.25);

    @media screen and (max-width: $tablet) {
      padding: 0 40px;
      // padding-left: 40px;
    }

    h2 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 18px;
      align-self: flex-start;
    }
    h1 {
      max-width: 600px;
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 18px;
      @media screen and (max-width: $tablet) {
        font-size: 40px;
      }
    }
    p {
      max-width: 500px;
      font-size: 20px;
      font-weight: 500;
      line-height: 34px;
      margin-bottom: 18px;
    }
  }

  &-btns {
    display: flex;
    align-items: center;
    justify-content: space-around;

    a {
      text-decoration: none;
      border-radius: 5px;
    }

    & > * {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 60px;
      min-width: 225px;
      padding: 15px 45px;

      text-align: center;
      font-weight: 600;
      letter-spacing: 1px;
    }

    &-listings {
      margin-right: 24px;

      color: #fff;
      background-color: lighten($color-primary, 20);
      border-bottom: 3px solid lighten($color-primary, 10);

      &:hover {
        background-image: linear-gradient(to top right, lighten($color-primary, 25), lighten($color-primary, 20));
      }
    }
    &-learn {
      color: lighten($color-primary, 20);
      border: 3px solid lighten($color-primary, 20);
      border-bottom-color: lighten($color-primary, 15);
    }
  }
}