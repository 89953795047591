@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap);
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif; }

body {
  width: 100vw;
  min-height: 100vh;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  overflow-x: hidden !important; }

a, a:visited, a:active {
  color: inherit;
  text-decoration: none; }

button {
  border: none;
  cursor: pointer;
  background-color: transparent; }
  button:focus {
    outline: none; }

.root {
  width: 100%; }

.App {
  width: 100%; }

.coming-soon {
  position: relative;
  width: 100vw;
  height: 100vh;
  color: #363945;
  background-color: #558a65; }
  .coming-soon-body {
    width: 70%;
    max-width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    padding: 64px 32px;
    color: #222;
    background-color: white;
    border-radius: 5px; }
    @media screen and (max-width: 900px) {
      .coming-soon-body {
        width: 90%; }
        .coming-soon-body h1 {
          font-size: 24px; } }
  .coming-soon-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px; }
  .coming-soon input {
    width: 400px;
    padding: 8px 12px;
    margin: 4px 0;
    border-radius: 4px;
    font-size: 16px; }
    @media screen and (max-width: 900px) {
      .coming-soon input {
        width: 95%; } }
  .coming-soon input[type="submit"] {
    cursor: pointer; }

.navbar {
  width: 100%;
  min-height: 60px;
  padding: 0 64px;
  border-bottom: 1px solid #f2f2f2;
  background-color: white;
  border-bottom: px solid #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media screen and (max-width: 900px) {
    .navbar {
      padding: 0 24px; } }
  .navbar a {
    margin: 23px 15px;
    color: #363945;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none; }
  .navbar a:hover {
    color: #558a65; }
  .navbar-brand svg {
    width: 24px;
    height: 24px;
    fill: #363945; }
    .navbar-brand svg:hover {
      fill: #558a65; }
  .navbar-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .navbar-menu-items {
    display: flex;
    align-items: center;
    z-index: 10; }
    @media screen and (max-width: 900px) {
      .navbar-menu-items {
        display: none; } }
  @media screen and (max-width: 900px) {
    .navbar-menu-items.is-active {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 60px;
      width: 100%;
      padding-left: 24px;
      background-color: #fff;
      border-bottom: 1px solid #ddd; } }
  .navbar-burger-icon {
    display: none; }
    @media screen and (max-width: 900px) {
      .navbar-burger-icon {
        display: inline; } }
    .navbar-burger-icon svg {
      width: 24px;
      height: 24px;
      fill: #363945;
      cursor: pointer; }
      .navbar-burger-icon svg:hover {
        fill: #558a65; }
  @media screen and (max-width: 900px) {
    .navbar-ctas {
      display: none; } }
  .navbar-ctas a {
    color: #fff;
    background-color: #558a65;
    border-bottom: 3px solid #416b4e;
    padding: 10px 32px;
    border-radius: 5px; }
    .navbar-ctas a:hover {
      color: #fff;
      background-image: -webkit-gradient(linear, left bottom, right top, from(#5e9a71), to(#558a65));
      background-image: linear-gradient(to top right, #5e9a71, #558a65); }

.hero {
  min-height: 568px;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(46, 75, 55, 0.4)), to(rgba(0, 0, 0, 0.1))), url(/static/media/bg-4.e37ce31e.jpg);
  background-image: linear-gradient(to right, rgba(46, 75, 55, 0.4), rgba(0, 0, 0, 0.1)), url(/static/media/bg-4.e37ce31e.jpg);
  background-size: cover;
  background-position: 0% 15%;
  background-repeat: no-repeat;
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: center; }
  .hero-text {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fafafa;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25); }
    @media screen and (max-width: 900px) {
      .hero-text {
        padding: 0 40px; } }
    .hero-text h2 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 18px;
      align-self: flex-start; }
    .hero-text h1 {
      max-width: 600px;
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 18px; }
      @media screen and (max-width: 900px) {
        .hero-text h1 {
          font-size: 40px; } }
    .hero-text p {
      max-width: 500px;
      font-size: 20px;
      font-weight: 500;
      line-height: 34px;
      margin-bottom: 18px; }
  .hero-btns {
    display: flex;
    align-items: center;
    justify-content: space-around; }
    .hero-btns a {
      text-decoration: none;
      border-radius: 5px; }
    .hero-btns > * {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      min-width: 225px;
      padding: 15px 45px;
      text-align: center;
      font-weight: 600;
      letter-spacing: 1px; }
    .hero-btns-listings {
      margin-right: 24px;
      color: #fff;
      background-color: #558a65;
      border-bottom: 3px solid #416b4e; }
      .hero-btns-listings:hover {
        background-image: -webkit-gradient(linear, left bottom, right top, from(#5e9a71), to(#558a65));
        background-image: linear-gradient(to top right, #5e9a71, #558a65); }
    .hero-btns-learn {
      color: #558a65;
      border: 3px solid #558a65;
      border-bottom-color: #4b7a5a; }

.highlights h2 {
  margin: 60px auto;
  text-align: center;
  font-size: 24px;
  font-weight: 600; }
  @media screen and (max-width: 900px) {
    .highlights h2 {
      font-size: 24px;
      max-width: 80%; } }

.highlights-panels {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 900px) {
    .highlights-panels {
      flex-direction: column; } }

.highlights .panel {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 33%;
  padding: 40px;
  border: 1px solid #efefef; }
  @media screen and (max-width: 900px) {
    .highlights .panel {
      width: 85%; } }
  .highlights .panel:first-of-type {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px; }
  .highlights .panel:last-of-type {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px; }
  .highlights .panel:first-of-type .panel-icon {
    background-color: rgba(85, 138, 101, 0.2); }
    .highlights .panel:first-of-type .panel-icon .primary {
      fill: #558a65; }
  .highlights .panel:nth-of-type(2) .panel-icon {
    background-color: rgba(178, 178, 162, 0.2); }
    .highlights .panel:nth-of-type(2) .panel-icon .primary {
      fill: #b2b2a2; }
  .highlights .panel:last-of-type .panel-icon {
    background-color: rgba(54, 54, 54, 0.2); }
    .highlights .panel:last-of-type .panel-icon .primary {
      fill: #363636; }
  .highlights .panel-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 999px; }
    .highlights .panel-icon .secondary {
      fill: #fff; }
    .highlights .panel-icon svg {
      width: 45px;
      height: 45px; }
  .highlights .panel-text {
    -webkit-transform: translateY(60px);
            transform: translateY(60px);
    -webkit-transition: -webkit-transform .15s ease-in-out 0s;
    transition: -webkit-transform .15s ease-in-out 0s;
    transition: transform .15s ease-in-out 0s;
    transition: transform .15s ease-in-out 0s, -webkit-transform .15s ease-in-out 0s; }
    .highlights .panel-text h3 {
      margin: 18px 0;
      font-size: 30px;
      font-weight: 500;
      line-height: 1.2; }
    .highlights .panel-text p {
      font-size: 20px;
      line-height: 1.2;
      opacity: 0;
      visibility: hidden; }
  .highlights .panel:active .panel-chevron {
    display: none; }
  .highlights .panel-chevron {
    align-self: center;
    position: absolute;
    bottom: -10px; }
    .highlights .panel-chevron svg {
      width: 70px;
      height: 70px;
      fill: #D6DADE; }
  .highlights .panel:hover {
    cursor: pointer;
    color: #fff; }
    .highlights .panel:hover .primary {
      fill: #fff; }
  .highlights .panel:first-of-type:hover {
    background-color: #558a65; }
    .highlights .panel:first-of-type:hover .primary {
      fill: #fff; }
    .highlights .panel:first-of-type:hover .secondary {
      fill: #558a65; }
  .highlights .panel:nth-of-type(2):hover {
    background-color: #b2b2a2; }
    .highlights .panel:nth-of-type(2):hover .primary {
      fill: #fff; }
    .highlights .panel:nth-of-type(2):hover .secondary {
      fill: #b2b2a2; }
  .highlights .panel:last-of-type:hover {
    background-color: #363636; }
    .highlights .panel:last-of-type:hover .primary {
      fill: #fff; }
    .highlights .panel:last-of-type:hover .secondary {
      fill: #363636; }
  .highlights .panel:hover .panel-icon {
    background-color: rgba(255, 255, 255, 0.2); }
  .highlights .panel:hover .panel-chevron svg {
    fill: #fff; }
  .highlights .panel:hover .panel-text {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
    .highlights .panel:hover .panel-text p {
      opacity: 1;
      visibility: visible; }

.testimonials {
  margin: 50px 0;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .testimonials [class$='header'] {
    margin: 60px auto;
    text-align: center;
    font-size: 24px;
    font-weight: 600; }
  .testimonials-gallery {
    position: relative; }
  .testimonials [class^='testimonials-gallery-nav'] {
    position: absolute;
    top: 50%;
    cursor: pointer;
    background-color: transparent;
    border: none; }
    .testimonials [class^='testimonials-gallery-nav']:hover .primary {
      fill: #ededed; }
    .testimonials [class^='testimonials-gallery-nav']:focus {
      outline: none; }
    .testimonials [class^='testimonials-gallery-nav'] .primary {
      fill: #f7f7f7;
      -webkit-transition: all .2s ease;
      transition: all .2s ease; }
    .testimonials [class^='testimonials-gallery-nav'] .secondary {
      fill: #558a65; }
    .testimonials [class^='testimonials-gallery-nav'] svg {
      width: 60px;
      height: 60px; }
  .testimonials .testimonials-gallery-nav-left {
    left: 10px; }
    @media screen and (max-width: 900px) {
      .testimonials .testimonials-gallery-nav-left {
        left: 0; } }
  .testimonials .testimonials-gallery-nav-right {
    right: 10px; }
    @media screen and (max-width: 900px) {
      .testimonials .testimonials-gallery-nav-right {
        right: 0; } }
  .testimonials-card {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    width: 80%;
    max-width: 1000px;
    min-height: 300px;
    border: 1px solid #d6dade;
    border-radius: 6px;
    box-shadow: 20px 20px #558a65; }
    @media screen and (max-width: 900px) {
      .testimonials-card {
        padding: 0;
        box-shadow: 8px 8px #558a65; } }
    .testimonials-card p {
      margin: 30px 0;
      font-size: 28px;
      line-height: 40px; }
      @media screen and (max-width: 900px) {
        .testimonials-card p {
          padding: 24px;
          padding-bottom: 4px;
          margin: 10px 0;
          font-size: 16px;
          line-height: 24px; } }
    .testimonials-card span {
      color: #595959;
      font-size: 45px;
      font-family: serif; }
      @media screen and (max-width: 900px) {
        .testimonials-card span {
          font-size: 30px; } }
    .testimonials-card-info {
      margin: 0 auto;
      display: flex;
      align-items: center; }
      @media screen and (max-width: 900px) {
        .testimonials-card-info {
          flex-direction: column; } }
      .testimonials-card-info-image {
        height: 70px;
        width: 70px;
        object-fit: cover;
        border-radius: 50%;
        border: 3px solid #558a65; }
      .testimonials-card-info-text-content {
        margin-left: 20px; }
        @media screen and (max-width: 900px) {
          .testimonials-card-info-text-content {
            display: flex;
            margin-left: 0;
            flex-direction: column;
            align-items: center; } }
      .testimonials-card-info-name {
        color: #505050;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 10px; }
        @media screen and (max-width: 900px) {
          .testimonials-card-info-name {
            font-size: 20px;
            margin: 4px 0 0 0; } }
      .testimonials-card-info-location {
        color: #b2b9c1;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px; }
        @media screen and (max-width: 900px) {
          .testimonials-card-info-location {
            font-size: 16px;
            margin-bottom: 12px; } }
  .testimonials .testimonial-enter {
    opacity: 0; }
  .testimonials .testimonial-enter-active {
    opacity: 1; }
  .testimonials .testimonial-exit {
    opacity: 1; }
  .testimonials .testimonial-exit-active {
    opacity: 0; }

@-webkit-keyframes fadeInFromBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fadeInFromBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.email-capture {
  background-color: #ebf3ee;
  width: 100%;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .email-capture-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 1000px;
    padding: 64px 20px 114px 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1); }
  .email-capture-title {
    color: #363945;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 25px; }
    .email-capture-title svg {
      width: 40px;
      height: 40px; }
  .email-capture-subtitle {
    max-width: 675px;
    line-height: 1.5;
    text-align: center;
    font-size: 21px;
    margin-bottom: 30px; }
  .email-capture-input-fields {
    width: 100%;
    max-width: 675px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .email-capture-input-fields input {
      width: 45%;
      padding: 10px 10px;
      font-size: 16px;
      border-radius: 3px;
      border: 1px solid #cccccc; }
      .email-capture-input-fields input:first-of-type {
        margin-right: 5px; }
  .email-capture-button {
    position: absolute;
    bottom: 0;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 0 0 5px 5px;
    background-color: #558a65;
    font-size: 18px;
    font-weight: 500; }
    .email-capture-button:hover {
      background-color: #4f815e; }
  .email-capture .icon-mail {
    width: 20px;
    height: 20px;
    fill: #fff;
    margin-right: 5px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: all .2s ease;
    transition: all .2s ease; }
  .email-capture-button:hover .icon-mail {
    opacity: 1;
    visibility: visible;
    -webkit-transform: rotate(-4deg) scale(1.12);
            transform: rotate(-4deg) scale(1.12); }

.listings {
  padding: 0 48px;
  padding-bottom: 80px; }
  @media screen and (max-width: 900px) {
    .listings {
      padding: 0 24px; } }
  .listings h2 {
    margin: 60px auto;
    text-align: center;
    font-size: 24px;
    font-weight: 600; }
    @media (max-width: 768px) {
      .listings h2 {
        font-size: 20px;
        padding: 0 12px; } }
  .listings-gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    .listings-gallery a {
      text-decoration: none;
      display: block; }

.listings-sort-by {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 22px;
  margin-bottom: 16px; }
  .listings-sort-by select {
    margin-left: 12px; }

.listing-card {
  width: 300px;
  display: block;
  overflow: hidden;
  margin: 22px 22px;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all .2s ease .05s;
  transition: all .2s ease .05s; }
  .listing-card:hover {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.35); }
  .listing-card:hover img {
    -webkit-transform: scale(1.08);
            transform: scale(1.08); }
  @media (max-width: 900px) {
    .listing-card {
      width: 40%;
      max-width: 300px;
      margin: 24px 22px; } }
  @media (max-width: 768px) {
    .listing-card {
      width: 80%;
      max-width: 300px;
      margin: 24px 22px; } }
  .listing-card-image-gallery {
    width: 100%;
    height: 200px;
    overflow: hidden; }
  .listing-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: all .2s ease .05s;
    transition: all .2s ease .05s; }
  .listing-card-content {
    padding: 12px;
    color: #363945;
    font-weight: 500; }
    .listing-card-content > * {
      line-height: 1.5; }
  .listing-card-title {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 600; }
    .listing-card-title span {
      color: rgba(54, 57, 69, 0.8);
      font-size: 16px;
      font-weight: 500; }
  .listing-card-subtitle {
    display: flex;
    align-items: center; }
  .listing-card [class^='icon'] {
    width: 18px;
    height: 18px; }
  .listing-card .primary, .listing-card .secondary {
    fill: rgba(54, 57, 69, 0.8); }

.listing-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px; }
  @media screen and (max-width: 900px) {
    .listing-details {
      margin-top: 0; } }
  .listing-details-img-gallery {
    width: 100%;
    height: 500px;
    overflow: hidden;
    border-radius: 8px;
    position: relative; }
    @media (max-width: 900px) {
      .listing-details-img-gallery {
        border-radius: 0px;
        height: 40vh; } }
    .listing-details-img-gallery img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    .listing-details-img-gallery .iframe-wrapper {
      width: 100% !important;
      height: 100%;
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 35px;
      height: 0;
      object-fit: cover;
      overflow: hidden; }
    .listing-details-img-gallery iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .listing-details-img-gallery [class^='nav'] {
      z-index: 20;
      position: absolute;
      top: 50%;
      color: white;
      font-size: 50px;
      border: none;
      outline: none;
      background-color: transparent;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
      @media screen and (max-width: 900px) {
        .listing-details-img-gallery [class^='nav'] {
          width: 50px;
          height: 50px; } }
    .listing-details-img-gallery .nav-left {
      left: 15px; }
      @media screen and (max-width: 900px) {
        .listing-details-img-gallery .nav-left {
          left: 0; } }
    .listing-details-img-gallery .nav-right {
      right: 15px; }
      @media screen and (max-width: 900px) {
        .listing-details-img-gallery .nav-right {
          right: 0; } }

.overview {
  width: 100%;
  margin: 24px 0;
  padding: 0 12px;
  color: #3b4144;
  font-size: 16px;
  display: flex; }
  @media screen and (max-width: 900px) {
    .overview {
      flex-direction: column; } }
  .overview-content {
    width: 66.6%; }
    @media screen and (max-width: 900px) {
      .overview-content {
        width: 100%;
        padding: 0 4px; } }
  .overview-flex-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between; }
    .overview-flex-container h2 {
      font-size: 28px;
      max-width: 480px; }
    .overview-flex-container h3 {
      font-size: 28px; }
  .overview-locale {
    font-size: 18px;
    margin: 8px 0;
    margin-bottom: 18px; }
  .overview-highlights {
    display: flex;
    align-items: center;
    font-size: 14px; }
    .overview-highlights svg {
      width: 22px;
      height: 22px;
      fill: #869099;
      margin-right: 4px; }
    .overview-highlights svg:not(:first-of-type) {
      margin-left: 10px; }
    .overview-highlights .icon-bolt .secondary {
      fill: white; }
  .overview-cta {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 16px; }
    @media screen and (max-width: 900px) {
      .overview-cta {
        width: 100%;
        margin-left: 0;
        margin-top: 28px; } }
  .overview-cta-panel {
    width: 100%;
    padding: 16px;
    border: 1px solid #e8e9ea;
    border-radius: 8px; }
  .overview-cta-header {
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
    margin-bottom: 8px; }
  .overview-cta-btn {
    width: 100%;
    display: inline-block;
    text-align: center;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 16px;
    background-color: #558a65; }
    .overview-cta-btn:hover {
      background-image: -webkit-gradient(linear, left bottom, right top, from(#5e9a71), to(#558a65));
      background-image: linear-gradient(to top right, #5e9a71, #558a65); }
    .overview-cta-btn:visited {
      color: white; }
  .overview-cta-info {
    font-size: 10px;
    line-height: 1.6;
    margin-bottom: 8px; }

.overview-local {
  margin: 56px 0; }
  .overview-local h3 {
    font-size: 20px;
    margin-bottom: 16px; }
  .overview-local-gallery {
    display: flex; }
  .overview-local-gallery-card {
    width: 162.5px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    cursor: pointer; }
  .overview-local-gallery-card-img {
    width: 100%;
    height: 104px;
    background-image: url(/static/media/map-placeholder.b34835ba.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px; }
  .overview-local h4 {
    font-size: 16px;
    margin: 8px 0 4px 0; }
  .overview-local p {
    font-size: 14px; }

.overview-description h3 {
  margin-bottom: 16px; }
  @media screen and (max-width: 900px) {
    .overview-description h3 {
      margin: 16px 0 8px 0; } }

.overview-description p {
  color: #3b4144;
  line-height: 24px;
  font-weight: 500; }

.map-embed {
  width: 100%;
  height: 354px;
  margin: 56px 0;
  padding: 0 12px;
  overflow: hidden;
  border-radius: 8px; }
  .map-embed iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px; }

.container {
  width: 992px; }
  @media (max-width: 1023px) {
    .container {
      width: 100%; } }

.property-details {
  margin: 56px 0;
  padding: 0 12px; }
  .property-details h3 {
    margin-bottom: 16px; }
  .property-details-bullets {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-left: 16px;
    line-height: 1.5; }
    .property-details-bullets li {
      width: 33.3%;
      margin-bottom: 12px;
      padding-right: 32px;
      font-size: 15px; }
      @media screen and (max-width: 900px) {
        .property-details-bullets li {
          width: 90%; } }
    .property-details-bullets span {
      font-weight: 600;
      margin-right: 4px; }

.about {
  height: 100%;
  min-height: 100vh;
  width: 100%; }
  .about-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10vw 2vw 9.5vw;
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.75)), to(rgba(0, 0, 0, 0.4))), url(/static/media/about-us-header.193b6ecd.jpg);
    background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.4)), url(/static/media/about-us-header.193b6ecd.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    @media screen and (max-width: 900px) {
      .about-header {
        padding: 0 28px 42px 28px; } }
    .about-header h1 {
      max-width: 900px;
      margin-bottom: 20px;
      font-size: 50px;
      font-weight: 600;
      line-height: 1.3; }
      @media screen and (max-width: 900px) {
        .about-header h1 {
          font-size: 38px;
          margin-top: 24px; } }
    .about-header p {
      max-width: 780px;
      font-size: 24px;
      line-height: 1.45; }
      @media screen and (max-width: 900px) {
        .about-header p {
          font-size: 16px; } }
  .about-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 10vw 2vw 9.5vw;
    background-color: #ebf3ee; }
    .about-main h2 {
      color: #363945;
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 20px; }
      @media screen and (max-width: 900px) {
        .about-main h2 {
          font-size: 38px; } }
    .about-main img {
      width: 400px;
      margin: 16px 0;
      -webkit-filter: sepia(0.15);
              filter: sepia(0.15);
      border-radius: 8px;
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1); }
      @media screen and (max-width: 900px) {
        .about-main img {
          width: 80%; } }
    .about-main p {
      max-width: 700px;
      color: #5b6b7f;
      font-size: 20px;
      line-height: 1.45; }
      @media screen and (max-width: 900px) {
        .about-main p {
          font-size: 18px;
          padding: 12px 20px 0 20px; } }

.Login {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .Login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    margin-top: 16px; }
    .Login-form input {
      width: 100%;
      padding: 12px 12px;
      margin: 4px 0;
      font-size: 16px;
      border-radius: 12px;
      border: 1px solid #ddd;
      background: none; }
    .Login-form button {
      margin-top: 10px;
      font-size: 18px; }

.Footer {
  width: 100%;
  color: #333;
  background-color: #f4f4f4; }
  .Footer-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly; }
    @media screen and (max-width: 900px) {
      .Footer-container {
        flex-direction: column;
        padding-bottom: 50px; } }
  .Footer-container > * {
    width: 30%; }
    @media screen and (max-width: 900px) {
      .Footer-container > * {
        width: 80%; } }
  .Footer-left {
    height: 165px; }
    @media screen and (max-width: 900px) {
      .Footer-left {
        height: auto;
        width: 200px; } }
    .Footer-left img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .Footer-mid {
    text-align: center; }
    @media screen and (max-width: 900px) {
      .Footer-mid {
        margin-bottom: 44px; } }
    .Footer-mid h3 {
      font-size: 30px;
      margin-bottom: 8px; }
  .Footer-right {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .Footer-right h4 {
      font-size: 24px;
      margin-bottom: 4px; }
    .Footer-right a {
      margin: 4px 0; }
      @media screen and (max-width: 900px) {
        .Footer-right a {
          font-size: 18px; } }

