.listing-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  @media screen and (max-width: $tablet) {
    margin-top: 0;
  }

  &-img-gallery {
    width: 100%;
    height: 500px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;

    @media (max-width: $tablet) {
      border-radius: 0px;
      height: 40vh;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .iframe-wrapper {
      width: 100% !important;
      height: 100%;
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 35px;
      height: 0;
      object-fit: cover;
      overflow: hidden;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }

    [class^='nav'] {
      z-index: 20;
      position: absolute;
      top: 50%;
      color: white;
      font-size: 50px;
      border: none;
      outline: none;
      background-color: transparent;
      transform: translate(0, -50%);

      @media screen and (max-width: $tablet) {
        width: 50px;
        height: 50px;
      }
    }
    .nav-left {
      left: 15px;

      @media screen and (max-width: $tablet) {
        left: 0;
      }
    }
    .nav-right {
      right: 15px;

      @media screen and (max-width: $tablet) {
        right: 0;
      }
    }
  }
}

.overview {
  width: 100%;
  margin: 24px 0;
  padding: 0 12px;
  color: rgb(59, 65, 68);
  font-size: 16px;

  display: flex;

  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }

  &-content {
    width: 66.6%;

    @media screen and (max-width: $tablet) {
      width: 100%;
      padding: 0 4px;
    }
  }
  &-flex-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    h2 {
      font-size: 28px;
      max-width: 480px;
    }
    h3 {
      font-size: 28px;
    }
  }
  &-locale {
    font-size: 18px;
    margin: 8px 0;
    margin-bottom: 18px;
  }
  &-highlights {
    display: flex;
    align-items: center;
    font-size: 14px;

    svg {
      width: 22px;
      height: 22px;
      fill: rgb(134, 144, 153);
      margin-right: 4px;
    }
    svg:not(:first-of-type){
      margin-left: 10px;
    }
    .icon-bolt {
      .secondary {
        fill: white;
      }
    }
  }

  // CTA code
  &-cta {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 16px;

    @media screen and (max-width: $tablet) {
      width: 100%;
      margin-left: 0;
      margin-top: 28px;
    }
  }
  &-cta-panel {
    width: 100%;
    padding: 16px;
    border: 1px solid rgb(232, 233, 234);
    border-radius: 8px;
  }
  &-cta-header {
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
    margin-bottom: 8px;
  }
  &-cta-btn {
    width: 100%;
    display: inline-block;
    text-align: center;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 16px;
    background-color: $color-primary-light;

    &:hover {
      background-image: linear-gradient(to top right, lighten($color-primary, 25), $color-primary-light);
    }
    &:visited {
      color: white;
    }
  }
  &-cta-info {
    font-size: 10px;
    line-height: 1.6;
    margin-bottom: 8px;
  }
}

.overview-local {
  margin: 56px 0;

  h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  &-gallery {
    display: flex;
  }
  &-gallery-card {
    width: 162.5px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  &-gallery-card-img {
    width: 100%;
    height: 104px;
    background-image: url('../../../assets/images/map-placeholder.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
  }

  h4 {
    font-size: 16px;
    margin: 8px 0 4px 0;
  }
  p { 
    font-size: 14px;
  }
}

.overview-description {
  h3 {
    margin-bottom: 16px;

    @media screen and (max-width: $tablet) {
      margin: 16px 0 8px 0;
    }
  }
  p {
    color: rgb(59, 65, 68);
    line-height: 24px;
    font-weight: 500;
    // letter-spacing: -.5px;
  }
}

.map-embed {
  width: 100%;
  height: 354px;
  margin: 56px 0;
  padding: 0 12px;
  overflow: hidden;
  border-radius: 8px;

  iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
  }
}

.container {
  width: 992px;
  @media (max-width: 1023px) {
    width: 100%;
  }
}

.property-details {
  margin: 56px 0;
  padding: 0 12px;

  h3 {
    margin-bottom: 16px;
  }

  &-bullets {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-left: 16px;

    line-height: 1.5;

    li {
      width: 33.3%;
      margin-bottom: 12px;
      padding-right: 32px;
      font-size: 15px;

      @media screen and (max-width: $tablet) {
        width: 90%;
      }
    }
    span {
      font-weight: 600;
      margin-right: 4px;
    }
  }
}